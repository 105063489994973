import React, { Fragment, useState, useEffect, useRef } from "react";
import Topbar from "../layouts/TopBar";
import Footer from "../layouts/Footer";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";


function About({isLoggedIn, loggedInUser}){
    const ulStyles = {
        opacity: '1000 !important', // Set the desired opacity value
    }
    
    const [users, setUsers] = useState([])
    const [activeUsers, setActiveUsers] = useState([])

    const loadUsers = () => {
        let resources = new UserResources()
        return resources.get_admin()
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerPadding: '20px', // Add padding on the sides
        slidesToShow: 5, // Adjust based on your need
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

    useEffect(() => {
        loadUsers().then(response => { 
            setUsers(response['body']); 
            setActiveUsers(response['body'].filter(user => user['enabled'] === '1' )); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    return(
        <Fragment>
            <Topbar/>  
            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                            <h1 style={{color:"#ffa800"}} className="block-title"><strong>About Us</strong></h1>
                        </div>
                    </div>
                </div>
            </div>
        {/* <!-- Main Content --> */}
        <div id="main-container">
    	<div className="content">
        	<div className="container">
            	<h2>OUR PASTORS</h2>
               	<p style={{color:"#ffa800"}} className="accent-color lead"><strong>Meet the Lead Pastors of the The Liberty Church London and Pastor in charge of the media team.</strong></p>
                <div className="spacer-20"></div>
                <div className="row">
                	<div className="col-md-4 col-sm-4">
                    	<div className="grid-item grid-staff-item">
                            <div className="grid-item-inner">
                              	<div className="media-box"><img src="https://i.imgur.com/3LxpDxp.jpg" alt="" style={{width: "100%", height:"100%", objectFit: "cover", display:"block", maxWidth:"100%", top:"0", left:"0"}}/></div>
                              	<div className="grid-item-content">
                                	<h3 style={{color:"#003165"}}>Pastor Sola Fola-Alade</h3>
                                    <span className="meta-data">Lead/Visionary Pastor</span>
                                	<ul className="social-icons-rounded social-icons-colored">
                                    	<li className="facebook"><a href="https://www.facebook.com/people/DrSola-Fola-Alade/100063780881442/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                        <li className="instagram"><a href="https://www.instagram.com/dr.solafolaalade/?hl=en" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                  	</ul>
                                	<p>The Visionary pastor of the Liberty Church.</p>
                              	</div>
                            </div>
                       	</div>
                    </div>
                	<div className="col-md-4 col-sm-4">
                    	<div className="grid-item grid-staff-item">
                            <div className="grid-item-inner">
                              	<div className="media-box"><img src="https://i.imgur.com/NCG8XJY.jpg" alt="" style={{width: "100%", height:"100%", objectFit: "cover", display:"block", maxWidth:"100%", top:"0", left:"0"}}/></div>
                              	<div className="grid-item-content">
                                	<h3 style={{color:"#003165"}}>Pastor Bimbo Fola Alade</h3>
                                    <span className="meta-data">Lead Pastor</span>
                                	<ul className="social-icons-rounded social-icons-colored">
                                        <li className="facebook"><a href="https://www.facebook.com/pastor.folaalade/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                        <li className="instagram"><a href="https://www.instagram.com/bimbo_folaalade/?hl=en" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                  	</ul>
                                	<p>Resident Pastor and wife to PSFA. 
                                    </p>
                              	</div>
                            </div>
                       	</div>
                    </div>
                	<div className="col-md-4 col-sm-4">
                    	<div className="grid-item grid-staff-item">
                            <div className="grid-item-inner">
                              	<div className="media-box"><img src="https://i.imgur.com/qdbikCO.jpg" alt="" style={{width: "100%", height:"100%", objectFit: "cover", display:"block", maxWidth:"100%", top:"0", left:"0"}}/></div>
                              	<div className="grid-item-content">
                                	<h3 style={{color:"#003165"}}>Pastor Ade Adewumi</h3>
                                    <span className="meta-data">Executive Pastor</span>
                                	<ul className="social-icons-rounded social-icons-colored">
                                        <li className="facebook"><a href="https://www.facebook.com/pastor.folaalade/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                        <li className="instagram"><a href="https://www.instagram.com/bimbo_folaalade/?hl=en" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                  	</ul>
                                	<p>Pastor In Charge of Media team.</p>
                              	</div>
                            </div>
                       	</div>
                    </div>                    
                </div>
           	</div>
         	<div className="spacer-75"></div>
           	<div className="lgray-bg padding-tb75" style={{opacity:1000}}>
            	<div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-5">
                            <h2 className="block-title">Team Members&amp;</h2>
                            <p>The Volunteers that make up the media team</p>
                        </div>
                        <div className="col-md-7 col-sm-7">
                            <div className="row">                            
                            {activeUsers.map((user, index) => {

                                do{
                                    return (
                                    <div key = {"ae_"+index} className="col-ms-4 col-sm-4 col-xs-4">
                                        <ul key ={"ae_"+index} className="carets">
                                            {(<li key={"ae_"+index}>{user['firstname'] + " " + user['lastname']}</li>)}        
                                        </ul>
                                    </div>
                                )
                                }
                                while (index % 10 !== 0)
                            }) }   
                            </div>
                        </div>
                    </div>

                    {                       
                        
                        activeUsers.length === 1 ?                       
                            
                        <div>
                            {isLoggedIn ? (                                
                                <>
                                    <a href={"/memberprofile?pid=3&mot=ia293b" + activeUsers[0]['id'] + "&intra=hjw20"}><img src={activeUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/></a>                            
                                    <p><a href={"/memberprofile?pid=3&mot=ia293b" + activeUsers[0]['id'] + "&intra=hjw20"}>{activeUsers[0]['firstname']}</a></p> 
                                </>
                                 
                            ):
                            (                               
                                <>
                                    <img src={activeUsers[0]['image']} style={{width: "200px", height:"200px"}} alt=""/>                          
                                    <p><a>{activeUsers[0]['firstname']}</a></p> 
                                </>
                                 
                            )
                            }
                            {
                            <>
                                <p><span className="meta-data">{activeUsers[0]['role.name']}</span></p>
                                <span className="meta-data">Joined: {moment(users[0]['date_of_joining']).format('MM/YYYY')}</span>
                            </>
                            }
                        </div>
                            
                            :
                            
                        <Slider {...settings}>
                            {activeUsers.map((member, index) => (
                                <div key={index}>
                                    { 
                                    isLoggedIn ?   
                                        <div>
                                            <a href={"/memberprofile?pid=3&mot=ia293b" + member['id'] + "&intra=yiw34"}><img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/></a> 
                                            <a style={{color:"#ffa800"}} href={"/memberprofile?pid=3&mot=ia293b" + member['id'] + "&intra=yiw34"}><strong>{member['firstname']}</strong></a> 
                                            <span className="meta-data">{member['role.name']}</span>                                    
                                            <span className="meta-data">Joined: {moment(member['date_of_joining']).format('MM/YYYY')}</span>
                                        </div>
                                        :
                                        <div>
                                            <img src={member['image']} style={{width: "200px", height:"200px"}} alt=""/>
                                            <p style={{color:"#ffa800"}}><strong>{member['firstname']}</strong></p>
                                        </div>                     
                                    }                                                                 
                                    
                                </div>
                            ))}
                        </Slider>

                    }     


                    

                    {/* <div className="carousel-wrapper">
                        <div className="row">                                          
                            <ul className="owl-carousel carousel-fw" data-columns="6" data-autoplay="" data-pagination="no" data-arrows="yes" data-single-item="no" data-items-desktop="6" data-items-desktop-small="4" data-items-tablet="3" data-items-mobile="2">
                            {                               
                                users.map((user, index) => {  
                                    return(
                                    <li key={index} className="item">
                                    <div className="grid-item grid-staff-item grid-staff-item-compact">
                                        <div className="grid-item-inner">
                                            <div className="media-box"><a href={"/memberprofile?pid=3&mot=" + user['id']}><img src={user['image']} style={{width: "200px", height:"200px"}} alt=""/></a></div>
                                            <div className="grid-item-content">
                                             <a href={"/memberprofile?pid=3&mot=" + user['id']}>{user['firstname']}</a>
                                                <span className="meta-data">{user['role.name']}</span>
                                                <span className="meta-data">Joined: {moment(user['date_of_joining']).format('MM/YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>)
                                 
                               })
                            }                            
                                                    
                            </ul>                                                    
                            
                        </div>
                    </div> */}
                </div>
           	</div>
            {
                isLoggedIn && loggedInUser['role.name'] === 'admin'  ? 
                <div style={{backgroundColor: "#003165"}} className="accent-bg padding-tb20 cta-fw">
                    <div className="container">
                        <a href="https://docs.google.com/spreadsheets/d/1lucUU4zEBaDV_JQz1YudZ-FzKSSEglX1/edit?usp=sharing&ouid=109519832316473962023&rtpof=true&sd=true" target="_blank" className="btn btn-default btn-ghost btn-light btn-rounded pull-right">Team Skills</a>
                        <h4>Knowledge Base within the Team</h4>
                    </div>
                </div>:<></>
            }   
            <div className="spacer-50"></div>
            {/* <div className="text-align-center">
                <h2>Join us now to make your impact</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis egestas rhoncus<br/>Donec facilisis fermentum sem, ac viverra ante luctus vel. Donec vel mauris quam.</p>
                <a href="#" className="btn btn-primary btn-lg">Join us today</a>
            </div> */}
        </div>
    </div>

            <Footer/>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

export default connect(mapStateToProps)(About);