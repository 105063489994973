import React, { Fragment, useState, useEffect } from "react";
import Topbar from "../layouts/TopBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AbsenceResources from "../resources/AbsenceResources";
import UserResources from "../resources/UserResources";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { connect } from "react-redux";
import { loginSuccess, logout } from "../redux/actions/LoggedInAction";
import {ACCESS_DENIED} from '../Messages'

function ManageUsers({isLoggedIn, loggedInUser}){  
  const [users, setUsers] = useState([])
  const userResources = new UserResources()

    const loadUsers = () => {
        
        return userResources.get_admin()
    }
     
      // Function to handle button clicks
      const handleApproveUserButtonClick = (user) => { 
        userResources.admin_approve_user_post(user).then(res => {
            if (res.error.length < 1) {
                alert("User Approved")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageusers';
                  }, 200);
            }
            else {
                alert("Approval Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      };
  
      // Function to handle button clicks
      const handleRefuseUserButtonClick = (user) => {      
        userResources.admin_refuse_user_post(user).then(res => {
            if (res.error.length < 1) {
                alert("User Refused. Speak to Seyi to Completely remove if needed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageusers';
                  }, 200);
            }
            else {
                alert("Approval Process Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };    
      
      const handleDisableUserButtonClick = (user) => {      
        userResources.admin_disable_user_post(user).then(res => {
            if (res.error.length < 1) {
                alert("User Disabled. Speak to Seyi to Completely remove if needed")
                setTimeout(() => {
                    window.location.href = window.location.origin + '/manageusers';
                  }, 200);
            }
            else {
                alert("Approval Process Failed.")
            }
            }).catch((err) => {
                toast.error("Network Error: " + err);
            });
      
  
      };  

    useEffect(() => {
        loadUsers().then(response => { 
            setUsers(response['body']); 
            toast.success("Successfully got data" )
        }).catch((err) => {
            toast.error("Network error: " + err)
        });
    }, []);

    
    return(
        <Fragment>
            <Topbar/>

            <div className="hero-area">
            <div className="page-banner parallax" style={{backgroundColor:"#003165"}}>
                    <div className="container">
                        <div className="page-banner-text">
                        {(isLoggedIn && loggedInUser['role.name'] === 'admin')?
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>All Members</strong></h1>
                                :
                                <h1 style={{color:"#ffa800"}} className="block-title"><strong>{ACCESS_DENIED}</strong></h1>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            

            <div id="main-container">
                {
                    (isLoggedIn && loggedInUser['role.name'] === 'admin') ? 
                
                <div className="content">
                    <div className="container">                    
                        <h3 style={{color: "#ffa800"}}><strong>Members Registered</strong></h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell><strong></strong></TableCell>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                                                      
                                {users.map((user) => (
                                    
                                    
                                    user['enabled'] === '1' ?
                                    
                                    <TableRow key={user['firstname']}>
                                    <TableCell><img src={user['image']} style={{width: "20px", height:"20px"}} alt=""/></TableCell>
                                    <TableCell>{user['firstname']}</TableCell>
                                    <TableCell>{user['lastname']}</TableCell>
                                    <TableCell><a href={"/memberprofile?pid=3&mot=ia293b" + user['id'] + "&intra=bk204x"}>View User</a> </TableCell>                                     
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleDisableUserButtonClick(user)} >Disable User</button> : <p></p>}</TableCell>                                     
                                    </TableRow> :
                                    <></>
                                ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>    
                        <div className="spacer-40"></div>

                        <h3 style={{color: "#ffa800"}}><strong>Members Pending Approval</strong></h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                <TableRow>
                                <TableCell><strong></strong></TableCell>
                                    <TableCell><strong>FirstName</strong></TableCell>
                                    <TableCell><strong>LastName</strong></TableCell>
                                    <TableCell><strong></strong></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {users.map((user) => (
                                    
                                    user['enabled'] === '0' ? 
                                    <TableRow key={user['firstname']}>
                                    <TableCell><img src={user['image']} style={{width: "20px", height:"20px"}} alt=""/></TableCell>
                                    <TableCell>{user['firstname']}</TableCell>
                                    <TableCell>{user['lastname']}</TableCell>
                                    <TableCell><a href={"/memberprofile?pid=3&mot=ia293b" + user['id'] + "&intra=bk204x"}>View User</a> </TableCell>                                     
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleApproveUserButtonClick(user)} >Approve User</button> : <p></p>}</TableCell>                                     
                                    <TableCell>{ loggedInUser !== null && loggedInUser['role.name'] === 'admin'? <button style={{marginLeft: "10px"}} onClick={() => handleRefuseUserButtonClick(user)} > Refuse User</button> : <p></p>}</TableCell>                                     
                                    </TableRow>:
                                   <></>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> 
                            
                           
                        

                            
                    </div>
                </div> : <></>
        }
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{
       isLoggedIn: state.authReducer.isLoggedIn,
       loggedInUser: state.authReducer.user
    };
    
};

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);